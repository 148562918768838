// dependencies
//@import 'animate';
@import 'config'; // variables declarations
@import 'mixins'; // useful mixins
@import 'engine/mixin-respondTo'; // useful mixins
// vendor
@import 'vendor/axiforma';
@import 'vendor/productsans';
@import 'vendor/fontawesome/fontawesome';
@import 'vendor/fontawesome/light';
// @import 'vendor/fontawesome/solid';
// @import 'vendor/fontawesome/brands';
@import 'vendor/fontawesome/regular';
// @import 'vendor/aos/aos';
@import 'animate';
@import 'vendor/slick/slick';
@import 'vendor/slick/slick-theme';

// helpers
@import 'placeholders'; // useful code snippets that can be extended using @extend
@import 'engine/helpers'; // useful code snippets that can be extended using @extend
@import 'engine/foundation';
// structure
@import 'custom-animations'; // custom animations
@import 'layout'; // defines the whole layout
@import 'mainbanner'; // defines the main banner
@import 'popoutmenu'; // defines the popout menu
@import 'one-stop-shop'; // defines the one stop shop
@import 'aquire-new-customers'; // defines aquire new customers
@import 'socialbanner'; // defines the social banner
@import 'parallax'; // text reveal effect
@import 'client-showcase'; // the logos animations
@import 'journey'; // journey animation
@import 'sliders'; // advantages section
@import 'buttons'; // button
@include breakpoint($md) {
    @import 'mobile'; // mobile only sections
}
// @import 'table';
@import 'modal';
@import 'form';
// content 
@import 'mixinsbasic';
@import 'grid';